body{
    zoom: 90%;
}
li .sidebar-submenu li a:after {
    display: none;
}
.container-fluid .card:first-of-type {
    margin-top: 20px;
}

.page-wrapper .page-body-wrapper .page-title {
    padding-top: 10px;
    padding-bottom: 10px;
}
.card-header .page-title {
    padding-top: 0px;
    padding-bottom: 0px;
}
.card .card-header {
    padding-top: 10px;
    padding-bottom: 10px;
}

.container-fluid div[class*="col-"] {
    margin-bottom: 10px;
}

.card .card-body{
    padding: 10px;
}
.p-datatable .p-datatable-thead > tr > th{
    padding: 10px;
}

.bg-none-active{
   background-color: #f443362e;
}